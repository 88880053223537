/** Libraries */
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledScales, StyledMobileScale } from './style';
import { ScaleWrapper } from '../../style';
/** components */
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
import Counter from './Counter';
/** constants */
import {
  PLURAL_PACKAGES,
  UNIT_TYPE_NAME,
  ZERO_BALANCE_TEXT,
} from '../../constants';
import { OPERATING_STATE } from '~/constants/common';
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { ScaleProps, STORAGE_TYPE } from '../../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** utils */
import { pluralizeAll } from '~/utils/utils';

const MobileScale: FC<ScaleProps> = ({ type }: ScaleProps) => {
  const {
    pab2cMobileStore: {
      getMaxUnitsStorage,
      getMaxUnitsSub,
      subscriptionsMinutes,
      subscriptionsGigabites,
      getStorageByType,
      isConvergentProduct,
    },
    contractStateStore: { contractState },
    vacationStore: { isActivated: isVacationActivated },
    productHistoryStore: { productHistory },
  } = useRootStore();
  const { getFillerColorForStorage } = useMobileStore();
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Характеристики накопителя */
  const storage = getStorageByType(type);
  const { currentQuantity, maxQuantity, accrualQuantity, startQuantity } =
    storage || {};

  /** Текущее значение накопителя + бонус */
  const jointCurrentQuantity = storage && currentQuantity + startQuantity;

  /** Накопитель почти израсходован */
  const isLeftMinimumQuantity =
    storage &&
    currentQuantity + startQuantity !== 0 &&
    currentQuantity + startQuantity < accrualQuantity;

  /** Подключенные пакеты */
  const typeToSubscriptionsMap = {
    [STORAGE_TYPE.SMS]: [],
    [STORAGE_TYPE.CALL]: subscriptionsMinutes,
    [STORAGE_TYPE.INTERNET]: subscriptionsGigabites,
  };

  const subscriptions = typeToSubscriptionsMap[type] || [];

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isVacationActivated;

  /** Форматирует дату => число, месяц */
  const getTrimDate = (date: string) =>
    format(new Date(date), 'd MMMM', {
      locale: ru,
    });

  /** Генерация ключей для списка */
  const ids = useMemo(() => subscriptions.map(() => nanoid(5)), [
    subscriptions,
  ]);

  /** Формирует описание для шкалы накопителя */
  const getStorageDescriptionText = () => {
    let text = '';

    if (jointCurrentQuantity === 0 && subscriptions.length === 0)
      text += `${ZERO_BALANCE_TEXT[type]}. `;
    if (startQuantity !== 0) text += `Бонус за${'\u00A0'}подключение. `;
    if (subscriptions.length === 1) text += 'Подключен пакет. ';
    if (subscriptions.length > 1)
      text += `Подключено${'\u00A0'}${pluralizeAll(
        subscriptions.length,
        PLURAL_PACKAGES,
      )}. `;
    if (currentQuantity > maxQuantity)
      text += `Перенос с${'\u00A0'}продукта ${
        productHistory[productHistory.length - 2]?.tariffName
      }. `;
    if (currentQuantity >= maxQuantity && subscriptions.length) {
      text += `Ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (currentQuantity === maxQuantity && !subscriptions.length) {
      text += `Накопитель заполнен, ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (!isSuspendedCondition && currentQuantity < maxQuantity) {
      text += `Ежедневно ${accrualQuantity}${'\u00A0'}${
        UNIT_TYPE_NAME[type]
      }${'\u00A0'}/${'\u00A0'}день`;
    }

    return text;
  };

  /** Формирует описание для шкалы пакета */
  const getSubscriptionDescriptionText = () => {
    if (subscriptions.length === 0 && !isSuspendedCondition)
      return ZERO_BALANCE_TEXT[type];
    if (subscriptions.length === 1)
      return `Пакет до ${getTrimDate(subscriptions[0].trimDt)}`;
    if (subscriptions.length > 1)
      return `Подключено ${subscriptions.length} пакета`;
    return '';
  };

  return (
    <StyledMobileScale>
      <Counter type={type} />
      {((!isDesktop940 && type !== STORAGE_TYPE.SMS) || isDesktop940) && (
        <StyledScales>
          {subscriptions.map((subscription, index) => (
            <ScaleWrapper
              $flexGrowValue={
                isConvergentProduct
                  ? subscription.currentQuantity / getMaxUnitsStorage(type)
                  : subscription.startQuantity / getMaxUnitsSub(type)
              }
              $isDisabled={isSuspendedCondition}
              key={ids[index]}
            >
              <FillableScale
                value={subscription.currentQuantity}
                maxValue={
                  isConvergentProduct
                    ? subscription.currentQuantity
                    : subscription.startQuantity
                }
                fillerColor={() => defaultTheme.colors.planeta}
              />
            </ScaleWrapper>
          ))}
          {isConvergentProduct && (
            <ScaleWrapper
              $flexGrowValue={maxQuantity / getMaxUnitsStorage(type)}
              $isDisabled={isSuspendedCondition}
              $isLeftMinimumQuantity={isLeftMinimumQuantity}
            >
              <FillableScale
                value={isLeftMinimumQuantity ? 1 : jointCurrentQuantity}
                maxValue={isLeftMinimumQuantity ? 1 : maxQuantity}
                fillerColor={() =>
                  getFillerColorForStorage(
                    storage,
                    isLeftMinimumQuantity,
                    jointCurrentQuantity,
                  )
                }
              />
            </ScaleWrapper>
          )}
        </StyledScales>
      )}
      <Text color={defaultTheme.colors.gray}>
        {isConvergentProduct
          ? getStorageDescriptionText()
          : getSubscriptionDescriptionText()}
      </Text>
    </StyledMobileScale>
  );
};

export default observer(MobileScale);
