import styled from '@emotion/styled';
import { H3, Button, LeadingText, Text } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledTab = styled.div`
  display: block;
  margin-bottom: 32px;
`;

export const StyledH3 = styled(H3)`
  display: inline;
  margin-right: 24px;
`;

export const StyledLeadingText = styled(LeadingText)`
  display: block;
  margin-bottom: 12px;
`;

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;

export const StyledText = styled(Text)`
  display: block;
  margin-top: 12px;
`;
