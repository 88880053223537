/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, SidePage } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import { StyledRadio, StyledSelect, StyledTabs } from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';

const AvailableNumbersSp = (): JSX.Element => {
  const {
    changeNumberStore: {
      isShowAvailableNumbersSp,
      setIsShowAvailableNumbersSp,
      numberCategoryNames,
      numberCategorieNamesMobile,
      unionCategoriesWithNumbers,
      activeCategoryTabIndex,
      setActiveCategoryTabIndex,
      candidateToNewNumber,
      setCandidateToNewNumber,
      newNumber,
      setNewNumberButtonHandler,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** По-умолчанию всегда выбираем первый номер телефона */
  useEffect(() => {
    if (!isShowAvailableNumbersSp) return;

    const categoryName = numberCategoryNames[activeCategoryTabIndex];
    setCandidateToNewNumber(unionCategoriesWithNumbers[categoryName][0]);
  }, [activeCategoryTabIndex, isShowAvailableNumbersSp]);

  const Footer = (): JSX.Element => (
    <Button
      onClick={setNewNumberButtonHandler}
      disabled={newNumber === candidateToNewNumber}
    >
      Выбрать номер
    </Button>
  );

  const NumbersList = (): JSX.Element => {
    const categoryName = numberCategoryNames[activeCategoryTabIndex];
    return unionCategoriesWithNumbers[categoryName].map((number) => (
      <StyledRadio
        checked={candidateToNewNumber === number}
        onChange={() => setCandidateToNewNumber(number)}
        title={number}
        description={categoryName}
        key={number}
      />
    ));
  };

  if (!isShowAvailableNumbersSp) return null;

  return (
    <SidePage
      show={isShowAvailableNumbersSp}
      headerText="Выберите ваш будущий номер"
      onCloseClick={() => setIsShowAvailableNumbersSp(false)}
      footerContainer={<Footer />}
      showMobileHeader
    >
      {isDesktop940 ? (
        <StyledTabs
          value={numberCategoryNames}
          onChange={(tabIndex) => setActiveCategoryTabIndex(tabIndex)}
          activeTabIndex={activeCategoryTabIndex}
          width="fit-content"
        />
      ) : (
        <StyledSelect
          placeholder="Выберите категорию"
          value={
            numberCategorieNamesMobile
              ? ((numberCategorieNamesMobile[activeCategoryTabIndex]
                  ?.value as unknown) as string)
              : ''
          }
          data={numberCategorieNamesMobile}
          onOptionClick={(option) => {
            setActiveCategoryTabIndex(option.value);
          }}
          visibleOptionCount={4}
          width="200px"
        />
      )}
      <NumbersList />
    </SidePage>
  );
};

export default observer(AvailableNumbersSp);
