import styled from '@emotion/styled';
import { Radio, Select, Tabs } from 'cordis-core-ui-planeta';

export const StyledTabs = styled(Tabs)`
  display: block;
  margin-bottom: 12px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 12px;
`;

export const StyledRadio = styled(Radio)`
  margin-bottom: 12px;
`;
