/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SidePage, Loader } from 'cordis-core-ui-planeta';
/** components */
import Tabs from './Tabs';
import SuccessOrder from '../../Components/SuccessOrder/SuccessOrder';
import ErrorHandler from './ErrorHandler';
/** styles */
import { StyledButton } from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** constants */
import { ACTIVATION_METHOD_TYPE } from '../../constants';
import { activationTypeButtonTextMap } from './constants';

const BindNewNumberSP = (): JSX.Element => {
  const {
    cityStore: { officeInfos },
    authStore: { auth },
  } = useRootStore();

  const {
    bindNewNumberStore: {
      isShowBindNewNumberSP,
      resetBindNewNumberStore,
      setIsShowBindNewNumberAgreementSP,
      bindTemporaryNumberForPortation,
      isLoading,
      result,
    },
    changeNumberStore: {
      newNumberInfo,
      getChangeNumberData,
      resetChangeNumberStore,
    },
    choosingTheActivationMethodStore: { activationMethodType },
    specifyTheTransferNumberStore: { phoneNumber },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
  } = useMobileStore();

  useEffect(() => {
    if (!isShowBindNewNumberSP) return;
    (async () => {
      await getChangeNumberData();
    })();
  }, [isShowBindNewNumberSP]);

  const buttonHandlerMap = {
    [ACTIVATION_METHOD_TYPE.NEW]: () => setIsShowBindNewNumberAgreementSP(true),
    [ACTIVATION_METHOD_TYPE.TRANSFER]: async () => {
      await bindTemporaryNumberForPortation(
        phoneNumber,
        deliveryType,
        deliveryAddress(officeInfos),
      );
    },
  };

  const isBalanceMoreThanBindPrice = auth.balance - newNumberInfo?.price >= 0;

  const getHeaderText = () => {
    if (!result.isResult) return 'Добавить номер';
    if (result.isResult && result.isCorrect) return 'SIM-карта заказа';
    return '';
  };

  const closeClickHandler = async () => {
    resetBindNewNumberStore();
    if (result.isResult && result.isCorrect) {
      await getChangeNumberData();
      resetChangeNumberStore();
    }
  };

  if (!isShowBindNewNumberSP) return null;

  const Footer = (): JSX.Element => (
    <StyledButton
      onClick={buttonHandlerMap[activationMethodType]}
      disabled={!isBalanceMoreThanBindPrice && newNumberInfo?.price !== 0}
      loading={isLoading}
    >
      {activationTypeButtonTextMap[activationMethodType]}
    </StyledButton>
  );

  return (
    <SidePage
      show={isShowBindNewNumberSP}
      headerText={getHeaderText()}
      onCloseClick={closeClickHandler}
      footerContainer={!result.isResult && <Footer />}
      showMobileHeader
    >
      {!newNumberInfo && !result.isResult && <Loader small />}

      {newNumberInfo && !result.isResult && <Tabs />}

      {result.isResult && result.isCorrect && <SuccessOrder />}
      {result.isResult && !result.isCorrect && <ErrorHandler />}
    </SidePage>
  );
};

export default observer(BindNewNumberSP);
