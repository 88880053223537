/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { defaultTheme, Icons, LeadingText, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledArrowIcon, StyledCounter, StyledFlexCenterBlock } from './style';
/** components */
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
import { UNIT_TYPE_NAME } from '../../constants';
/** types */
import { ScaleProps, STORAGE_TYPE } from '../../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const Counter: FC<ScaleProps> = ({ type }: ScaleProps) => {
  const {
    pab2cMobileStore: {
      getUnspentUnits,
      subscriptionsMinutes,
      subscriptionsGigabites,
      getStorageByType,
      isConvergentProduct,
    },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
  } = useRootStore();
  const { setIsShowDetailingTheLeftovers } = useMobileStore();

  /** Характеристики накопителя */
  const storage = getStorageByType(type);
  const { currentQuantity, maxQuantity, startQuantity } = storage || {};

  /** Текущее значение накопителя + бонус */
  const jointCurrentQuantity = storage && currentQuantity + startQuantity;

  /** Подключенные пакеты */
  const typeToSubscriptionsMap = {
    [STORAGE_TYPE.SMS]: [],
    [STORAGE_TYPE.CALL]: subscriptionsMinutes,
    [STORAGE_TYPE.INTERNET]: subscriptionsGigabites,
  };

  const subscriptions = typeToSubscriptionsMap[type] || [];

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  const textColor = isSuspendedCondition
    ? defaultTheme.colors.disable
    : defaultTheme.colors.black;

  if (!isConvergentProduct)
    return (
      <StyledCounter>
        {subscriptions.length === 0 && (
          <LeadingText color={textColor}>0 {UNIT_TYPE_NAME[type]}</LeadingText>
        )}
        {subscriptions.length === 1 && (
          <>
            <LeadingText color={textColor}>
              {subscriptions[0].currentQuantity}
            </LeadingText>
            <Text color={defaultTheme.colors.gray}>{`${'\u00A0'}/ ${
              subscriptions[0].startQuantity
            } ${UNIT_TYPE_NAME[type]}`}</Text>
          </>
        )}
        {subscriptions.length > 1 && (
          <StyledFlexCenterBlock
            onClick={() => setIsShowDetailingTheLeftovers(true, type)}
          >
            <LeadingText color={textColor}>
              {getUnspentUnits(type)} {UNIT_TYPE_NAME[type]}
            </LeadingText>
            <LinkWrapper>
              <StyledArrowIcon icon={<Icons.ArrowDownIcon />} />
            </LinkWrapper>
          </StyledFlexCenterBlock>
        )}
      </StyledCounter>
    );

  return (
    <StyledCounter>
      {subscriptions.length === 0 && (
        <>
          <LeadingText
            color={textColor}
          >{`${jointCurrentQuantity}`}</LeadingText>
          <Text
            color={defaultTheme.colors.gray}
          >{`${'\u00A0'}/ ${maxQuantity} ${UNIT_TYPE_NAME[type]}`}</Text>
        </>
      )}
      {subscriptions.length > 0 && (
        <StyledFlexCenterBlock
          onClick={() => setIsShowDetailingTheLeftovers(true, type)}
        >
          <LeadingText color={textColor}>
            {getUnspentUnits(type) + jointCurrentQuantity}{' '}
            {UNIT_TYPE_NAME[type]}
          </LeadingText>
          <LinkWrapper>
            <StyledArrowIcon icon={<Icons.ArrowDownIcon />} />
          </LinkWrapper>
        </StyledFlexCenterBlock>
      )}
    </StyledCounter>
  );
};

export default observer(Counter);
