/** libraries */
import React, { FC, useEffect, useState } from 'react';
import { defaultTheme, Loader, Text } from 'cordis-core-ui-planeta';
import Image from 'next/image';
/** constants */
import { ACTIVATION_METHOD_TYPE, SUCCESS_IMAGE } from '../../constants';
import { EMAIL_CLASS_ID } from '~/components/Blocks/Shared/ContactsAndNotifications/constants';
/** styles */
import {
  ImageWrapper,
  StyledH3,
  StyledLeadingText,
  StyledText,
  StyledWarning,
} from './styles';
/** utils */
import { maskPhone } from '~/utils/utils';
/** api */
import { getContacts } from '~/api/apiPab2c';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** types */
import { DELIVERY_TYPE } from '../../types';

const SuccessOrder: FC = () => {
  const {
    cityStore: { officeInfos },
    pab2cMobileStore: { getMobilePhoneById, getMobileInfo, isLoading },
  } = useRootStore();

  const {
    methodOfReceiptFormStore: { deliveryAddress, deliveryType },
    choosingTheActivationMethodStore: { activationMethodType },
    bindNewNumberStore: { bindedPhoneNumberId },
  } = useMobileStore();

  const [phone, setPhone] = useState('');

  const imageLoader = ({ src, width, quality }) => {
    return `${process.env.STATIC_SERVER}/${src}?w=${width}&q=${quality}`;
  };

  const titleMap = {
    [ACTIVATION_METHOD_TYPE.NEW]: 'Ваш будущий номер:',
    [ACTIVATION_METHOD_TYPE.TRANSFER]: 'Ваш временный номер:',
  };

  const deliveryTypeMap = {
    [DELIVERY_TYPE.COURIER]: 'курьером',
    [DELIVERY_TYPE.SELF_PICKUP]: 'в офисе',
  };

  useEffect(() => {
    (async () => {
      await getMobileInfo();

      try {
        const res = await getContacts();
        const onlyPhones = res.filter(
          (contact) => contact.contactValueClassId !== EMAIL_CLASS_ID,
        );
        if (onlyPhones.length) {
          setPhone(maskPhone(onlyPhones[0].value));
        }
      } catch (e) {
        console.error('getContacts', e);
      }
    })();
  }, []);

  const newNumber = getMobilePhoneById(bindedPhoneNumberId)?.msisdn;

  return (
    <div>
      <ImageWrapper>
        <Image
          loader={imageLoader}
          src={SUCCESS_IMAGE}
          alt="Заказ совершён успешно"
          width={362}
          height={362}
          quality={100}
          layout="fixed"
        />
      </ImageWrapper>
      <StyledLeadingText color={defaultTheme.colors.black}>
        {titleMap[activationMethodType]}
      </StyledLeadingText>
      <StyledH3>
        {isLoading || !newNumber ? <Loader small /> : maskPhone(newNumber)}
      </StyledH3>
      <StyledWarning>
        <Text>
          Мы свяжемся с&nbsp;вами по&nbsp;номеру, указанному в&nbsp;договоре:{' '}
          <Text fontWeightBold>{phone}</Text>
        </Text>
      </StyledWarning>
      {activationMethodType === ACTIVATION_METHOD_TYPE.TRANSFER && (
        <StyledText>
          С&nbsp;помощью временного номера вы можете пользоваться услугами
          Планеты, пока мы&nbsp;переносим номер. Если номер не&nbsp;удастся
          перенести из-за несоблюдения условий переноса, вы&nbsp;можете
          продолжить пользоваться временным номером.
        </StyledText>
      )}
      <Text>
        {`Вы выбрали получение SIM-карты ${
          deliveryTypeMap[deliveryType]
        } по адресу: ${deliveryAddress(officeInfos)}`}
      </Text>
    </div>
  );
};

export default SuccessOrder;
