/** libraries */
import { flow, getParent, Instance, types, cast } from 'mobx-state-tree';
/** constants */
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
import { IMobileStore } from './MobileStore';
import { maskPhone } from '~/utils/utils';
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { getReplaceSimCardAgreement, replaceSimCard } from '~/api/apiPab2c';
import { ContractResourceMobiles, DELIVERY_TYPE, EXEPTIONS } from '../types';

const RequestsStateModel = types.model('SIMCardReplacementStoreRequestsState', {
  getReplaceSimCardAgreement: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetReplaceSimCardAgreement',
  ),
  replaceSimCard: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/ReplaceSimCard',
  ),
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const SIMCardReplacementStore = types
  .model('SIMCardReplacementStore', {
    requestsState: RequestsStateModel,
    isShowSIMCardReplacementStore: types.boolean,
    result: ResultModel,
    replaceSimCardAgreement: types.string,
  })
  .views((self) => ({
    get SIMCardReplacementSPHeaderText() {
      const { droplistSelectedNumberId }: IMobileStore = getParent(self);
      const maskNumber = maskPhone(droplistSelectedNumberId);
      return `Замена SIM-карты номера ${maskNumber}`;
    },
    get isLoadingReplaceSimCardAgreement() {
      return self.requestsState.getReplaceSimCardAgreement.isLoading;
    },
    get isLoadingReplaceSimCard() {
      return self.requestsState.replaceSimCard.isLoading;
    },
  }))
  .actions((self) => ({
    setResult: (result) => {
      self.result = result;
    },
    onCloseSIMCardReplacementStoreSP: () => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      setDroplistSelectedNumberId('');
      self.isShowSIMCardReplacementStore = false;
      self.requestsState = cast({
        getReplaceSimCardAgreement: defaultModelState,
        replaceSimCard: defaultModelState,
      });
      self.replaceSimCardAgreement = '';
      self.result = DEFAULT_RESULT;
    },
    getReplaceSimCardAgreement: flow(function* (simId: number) {
      self.requestsState.getReplaceSimCardAgreement.reset();
      self.requestsState.getReplaceSimCardAgreement.setLoading();
      try {
        const res = yield getReplaceSimCardAgreement(simId);
        self.replaceSimCardAgreement = res;
        self.requestsState.getReplaceSimCardAgreement.setSuccess();
      } catch (e) {
        console.error('getReplaceSimCardAgreement', e);
        self.requestsState.getReplaceSimCardAgreement.setFail();
      }
    }),
    replaceSimCard: flow(function* (
      simId: number,
      deliveryType: DELIVERY_TYPE,
      deliveryAddress: string,
    ) {
      self.requestsState.replaceSimCard.reset();
      self.requestsState.replaceSimCard.setLoading();
      try {
        yield replaceSimCard(simId, deliveryType, deliveryAddress);
        self.requestsState.replaceSimCard.setSuccess();
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Ура! Вы успешно отправили заявку на замену SIM.',
        };
      } catch (e) {
        const error = JSON.parse(e.errorMessage);
        self.result = {
          isResult: true,
          isCorrect: false,
          text:
            error.Type === EXEPTIONS.CLIENT_REQUEST_DEMAND_EXEPTION
              ? 'Количество заявок в день превышено. Попробуйте повторить завтра.'
              : DEFAULT_ERROR,
        };
        self.requestsState.replaceSimCard.setFail();
      }
    }),
  }))
  .actions((self) => ({
    openSIMCardReplacement: (number: ContractResourceMobiles) => {
      self.getReplaceSimCardAgreement(number.simId);
      self.isShowSIMCardReplacementStore = true;
    },
  }));

export type ISIMCardReplacementStore = Instance<typeof SIMCardReplacementStore>;

export const SIMCardReplacementStoreInstance = {
  requestsState: {
    getReplaceSimCardAgreement: defaultModelState,
    replaceSimCard: defaultModelState,
  },
  isShowSIMCardReplacementStore: false,
  result: DEFAULT_RESULT,
  replaceSimCardAgreement: '',
};
