/** libraries */
import { Instance, types } from 'mobx-state-tree';
/** constants */
import { DEFAULT_RESULT } from '~/constants/common';

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const ChoosingTheReceivingMethodStore = types
  .model('ChoosingTheReceivingMethodStore', {
    isShowChoosingTheReceivingMethod: types.boolean,
    result: ResultModel,
  })
  .actions((self) => ({
    setIsShowChoosingTheReceivingMethod: (isShowChoosingTheReceivingMethod) => {
      self.isShowChoosingTheReceivingMethod = isShowChoosingTheReceivingMethod;
    },
    setResult: (result) => {
      self.result = result;
    },
    onCloseChoosingTheReceivingMethodSP: () => {
      self.isShowChoosingTheReceivingMethod = false;
    },
  }));

export type IChoosingTheReceivingMethodStore = Instance<
  typeof ChoosingTheReceivingMethodStore
>;

export const choosingTheReceivingMethodStoreInstance = {
  isShowChoosingTheReceivingMethod: false,
  result: DEFAULT_RESULT,
};
