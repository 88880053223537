/** libraries */
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  Icons,
  LeadingText,
  Text,
  Button,
  ButtonStyleTypes,
  ButtonIconPosition,
  DroplistPosition,
  H3,
  Droplist,
} from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledHead,
  StyledInfo,
  StyledMobileStatus,
  StyledMobileTitle,
  StyledNumberListItem,
  StyledServiceStatus,
  StyledStatus,
  StyledWrapper,
} from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** hook */
import { useMobileHook } from '../../MobileHook';
/** constants */
import { SERVICE_STATUS } from '../../constants';
import { OPERATING_STATE } from '~/constants/common';
import { desktop940 } from '~/components/Grid/constants';
/** utils */
import { maskPhone } from '~/utils/utils';
/** interfaces */
import { MOBILE_ACTIONS } from '../../types';
/** components */
import Portal from '~/components/Portal/Portal';
import NumberListButtons from '~/components/Blocks/Templates/Pab2c/Mobile/Components/NumberListItem/NumberListButtons';

interface NumberListItemProps {
  msisdn: string;
}

const NumberListItem: FC<NumberListItemProps> = ({
  msisdn,
}: NumberListItemProps) => {
  const {
    contractStateStore: { contractState },
    pab2cMobileStore: { mobilePhonesInfoData, getMobilePhone },
  } = useRootStore();
  const {
    droplistSelectedNumberId,
    setDroplistSelectedNumberId,
  } = useMobileStore();
  const { isProviderBlockForNonPayment } = useMobileHook();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const mobilePhone = useMemo(() => {
    return getMobilePhone(msisdn);
  }, [mobilePhonesInfoData]);

  const { isActive, simCardId, availableMobileActions } = mobilePhone;

  /** Сим залокирована автоматикой */
  const isAutoDisable =
    !isActive &&
    availableMobileActions.includes(MOBILE_ACTIONS.ActivateSimCard);

  /** Сим заблокирована пользователем */
  const isClientDisable =
    !isActive && availableMobileActions.includes(MOBILE_ACTIONS.Unblock);

  const serviceStatus: SERVICE_STATUS = useMemo(() => {
    if (
      isProviderBlockForNonPayment ||
      contractState === OPERATING_STATE.CLIENT_BLOCK
    ) {
      return SERVICE_STATUS.STOP;
    }
    if (isClientDisable) return SERVICE_STATUS.BLOCK;
    if (!simCardId) return SERVICE_STATUS.NOT_LINKED_TO_SIM;
    if (isAutoDisable && simCardId) return SERVICE_STATUS.SIM_NOT_ACTIVATED;
    return SERVICE_STATUS.ON;
  }, [isProviderBlockForNonPayment, contractState, mobilePhone]);

  const statusColor = isActive
    ? defaultTheme.colors.green
    : defaultTheme.colors.disable;

  const phoneName = mobilePhone?.subscriberName ?? 'Мой номер';
  const phoneNumber = mobilePhone?.msisdn
    ? maskPhone(mobilePhone.msisdn)
    : 'Выберите номер';

  const mobileNodeTitle = () => {
    return (
      <StyledMobileTitle>
        <H3>{phoneName}</H3>
        <LeadingText color={defaultTheme.colors.black}>
          {phoneNumber}
        </LeadingText>
        <StyledMobileStatus>
          <StyledStatus color={statusColor} />
          <Text>{serviceStatus}</Text>
        </StyledMobileStatus>
      </StyledMobileTitle>
    );
  };

  return (
    <StyledNumberListItem>
      <StyledWrapper>
        <StyledHead>
          <Icons.SimPinkIcon />
          <LeadingText color={defaultTheme.colors.black}>
            {phoneName}
          </LeadingText>
        </StyledHead>
        <StyledInfo>
          {isDesktop940 && <Text lineHeight="24px">{phoneNumber}</Text>}
          <StyledServiceStatus>
            <StyledStatus color={statusColor} />
            {isDesktop940 && <Text lineHeight="24px">{serviceStatus}</Text>}
          </StyledServiceStatus>
          {!isDesktop940 && <Text lineHeight="24px">{phoneNumber}</Text>}
        </StyledInfo>
      </StyledWrapper>
      <Droplist
        Portal={Portal}
        buttons={NumberListButtons(msisdn)}
        mobileTitle={`${phoneName}: ${phoneNumber}`}
        mobileNodeTitle={mobileNodeTitle()}
        isOpen={droplistSelectedNumberId === msisdn}
        onOpenClick={() => setDroplistSelectedNumberId(msisdn)}
        onCloseClick={() => setDroplistSelectedNumberId('')}
        onClickMobileBackIcon={() => setDroplistSelectedNumberId('')}
        position={DroplistPosition.BOTTOM_LEFT}
        width="330px"
        hasMobileBackIcon
      >
        <Button
          styleType={ButtonStyleTypes.ACTION}
          icon={<Icons.ThreeDotsIcon />}
          iconPosition={ButtonIconPosition.CENTER}
        />
      </Droplist>
    </StyledNumberListItem>
  );
};

export default observer(NumberListItem);
