/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { H2, H3, Icons, SidePage } from 'cordis-core-ui-planeta';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** styles */
import { StyledIcon } from '../../style';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import MethodOfReceiptForm from '../../Components/MethodOfReceiptForm/MethodOfReceiptForm';
import { useRootStore } from '~/stores/RootStore';
import { StyledButton } from '../BindNewNumberSP/styles';

const ChoosingTheReceivingMethod = (): JSX.Element => {
  const {
    cityStore: { city, officeInfos },
  } = useRootStore();

  const {
    choosingTheReceivingMethodStore: {
      isShowChoosingTheReceivingMethod,
      result,
      onCloseChoosingTheReceivingMethodSP,
    },
    methodOfReceiptFormStore: {
      setCheckedOfficeId,
      setSelectedCity,
      filteredOfficeInfos,
      selectedCity,
      isAcceptButtonDisabled,
      acceptDeliveryAddress,
    },
    bindNewNumberStore: { isShowBindNewNumberSP },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  useEffect(() => {
    setSelectedCity({
      label: city.name,
      value: city.name,
    });
  }, []);

  useEffect(() => {
    if (selectedCity.value && isShowBindNewNumberSP) {
      setCheckedOfficeId(filteredOfficeInfos(officeInfos)[0]?.id);
    }
  }, [selectedCity.value, isShowBindNewNumberSP]);

  if (!isShowChoosingTheReceivingMethod) return null;

  const acceptAddressButtonHandler = () => {
    acceptDeliveryAddress();
    onCloseChoosingTheReceivingMethodSP();
  };

  const Footer = () => (
    <StyledButton
      onClick={acceptAddressButtonHandler}
      disabled={isAcceptButtonDisabled}
    >
      Подтвердить
    </StyledButton>
  );

  return (
    <SidePage
      show={isShowChoosingTheReceivingMethod}
      headerText={!result.isResult && 'Выберите способ получения'}
      onCloseClick={onCloseChoosingTheReceivingMethodSP}
      footerContainer={!result.isResult && <Footer />}
    >
      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}
      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
      {!result.isResult && <MethodOfReceiptForm />}
    </SidePage>
  );
};

export default observer(ChoosingTheReceivingMethod);
