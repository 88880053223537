/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { isAfter, parseISO } from 'date-fns';
import {
  defaultTheme,
  LeadingText,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** components */
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
/** interfaces */
import { STORAGE_TYPE } from '../../types';
/** styles */
import { StyledScale } from './styles';
/** utils */
import { getDate } from '~/utils/utils';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
import { UNIT_TYPE_NAME } from '../../constants';

const DetailingTheLeftoversSP = (): JSX.Element => {
  const {
    pab2cMobileStore: {
      subscriptionsGigabites,
      subscriptionsMinutes,
      isConvergentProduct,
      getStorageByType,
    },
    productHistoryStore: { productHistory },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
  } = useRootStore();
  const {
    isShowDetailingTheLeftovers,
    detailingType,
    detailingSPHeaderText,
    setIsShowDetailingTheLeftovers,
    getFillerColorForStorage,
  } = useMobileStore();

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  /** Характеристики накопителя */
  const storage = getStorageByType(detailingType);
  const { currentQuantity, maxQuantity, accrualQuantity, startQuantity } =
    storage || {};

  /** Текущее значение накопителя + бонус */
  const jointCurrentQuantity = storage && currentQuantity + startQuantity;

  /** Накопитель почти израсходован */
  const isLeftMinimumQuantity =
    storage &&
    currentQuantity + startQuantity !== 0 &&
    currentQuantity + startQuantity < accrualQuantity;

  const subscriptions = useMemo(() => {
    const arr =
      detailingType === STORAGE_TYPE.INTERNET
        ? subscriptionsGigabites
        : subscriptionsMinutes;
    return arr
      .sort((a, b) => {
        return isAfter(parseISO(a.trimDt), parseISO(b.trimDt)) ? 1 : -1;
      })
      .map((subscription) => ({
        ...subscription,
        id: nanoid(5),
      }));
  }, [detailingType]);

  const count = (item) => {
    return (
      <div>
        <LeadingText color={defaultTheme.colors.black}>
          {item?.currentQuantity}{' '}
        </LeadingText>
        <Text lineHeight="24px" color={defaultTheme.colors.gray}>
          /{' '}
          {item?.maxQuantity ?? isConvergentProduct
            ? item?.currentQuantity
            : item?.startQuantity}{' '}
          {UNIT_TYPE_NAME[detailingType]}
        </Text>
      </div>
    );
  };

  /** Формирует описание для шкалы накопителя */
  const getStorageDescriptionText = () => {
    let text = '';
    if (currentQuantity > maxQuantity)
      text += `Перенос с${'\u00A0'}продукта ${
        productHistory[productHistory.length - 2]?.tariffName
      }. `;
    if (currentQuantity >= maxQuantity && subscriptions.length) {
      text += `Ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (currentQuantity === maxQuantity && !subscriptions.length) {
      text += `Накопитель заполнен, ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (!isSuspendedCondition && currentQuantity < maxQuantity) {
      text += `Ежедневно ${accrualQuantity}${'\u00A0'}${
        UNIT_TYPE_NAME[detailingType]
      }${'\u00A0'}/${'\u00A0'}день`;
    }

    return text;
  };

  if (!isShowDetailingTheLeftovers) return null;

  return (
    <SidePage
      show={isShowDetailingTheLeftovers}
      headerText={detailingSPHeaderText}
      onCloseClick={() => setIsShowDetailingTheLeftovers(false, null)}
    >
      {subscriptions.map((subscription) => (
        <StyledScale key={subscription.id}>
          {count(subscription)}
          <FillableScale
            value={subscription.currentQuantity}
            maxValue={
              isConvergentProduct
                ? subscription.currentQuantity
                : subscription.startQuantity
            }
            fillerColor={() => defaultTheme.colors.planeta}
          />
          {subscription?.trimDt && (
            <Text lineHeight="24px" color={defaultTheme.colors.gray}>
              Пакет до {getDate(subscription.trimDt, 'd MMMM')}
            </Text>
          )}
        </StyledScale>
      ))}
      {isConvergentProduct && (
        <StyledScale>
          {count(storage)}
          <FillableScale
            value={isLeftMinimumQuantity ? 1 : jointCurrentQuantity}
            maxValue={isLeftMinimumQuantity ? 1 : maxQuantity}
            fillerColor={() =>
              getFillerColorForStorage(
                storage,
                isLeftMinimumQuantity,
                jointCurrentQuantity,
              )
            }
          />
          <Text lineHeight="24px" color={defaultTheme.colors.gray}>
            {getStorageDescriptionText()}
          </Text>
        </StyledScale>
      )}
    </SidePage>
  );
};

export default observer(DetailingTheLeftoversSP);
