/** библиотеки */
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  H3,
  LeadingText,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
/** компоненты */
import { StyledSummaryDescription } from '../Summary.style';
import LinkWrapper from '~/components/LinkWrapper';
/** типы */
import { ActionType, Tag as TagType } from '../Summary.types';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

/** интерфейс компонента описание тарифа */
interface SummaryDescriptionProps extends ActionType {
  tags: TagType[];
  seriesName: string;
  marketingGroupName: string;
  marketingText: string;
  isBusiness: boolean;
  productFeedLink: string;
  marketingGroupCode: string;
  /** Открыть продукт в новой вкладке */
  isLinkInNewTab?: boolean;
  isClosed: boolean;
}

/**
 * компонент описания тарифа
 * @param {Tag[]} tags
 * @param {string} seriesName
 * @param {string} marketingGroupName
 * @param {string} marketingText
 * @param {boolean} isBusiness
 * @param {string} productFeedLink
 * @param {ActionType} action
 * @param {boolean} isClosed
 */
const SummaryDescription: React.FC<SummaryDescriptionProps> = ({
  tags,
  seriesName,
  marketingGroupName,
  marketingText,
  isBusiness,
  productFeedLink,
  action,
  marketingGroupCode,
  isLinkInNewTab,
  isClosed,
}: SummaryDescriptionProps) => {
  const getSubtitleColor = () => {
    return action?.color ?? defaultTheme.colors.gray;
  };
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const summaryBody = (
    <div className="container padding">
      {tags.length > 0 && (
        <div className="tags">
          {tags.map((tag) => (
            <Tag
              color={action?.color ?? defaultTheme.colors.planeta}
              colorTag={action?.color ?? defaultTheme.colors.pink}
              key={tag.code}
            >
              {tag.name.toUpperCase()}
            </Tag>
          ))}
        </div>
      )}
      <div>
        <LinkWrapper href={productFeedLink} target="_blank">
          {isDesktop940 ? (
            <H3 className="title" color={action?.color}>
              {seriesName}
            </H3>
          ) : (
            <LeadingText
              className="title"
              color={action?.color ?? defaultTheme.colors.black}
            >
              {seriesName}
            </LeadingText>
          )}
        </LinkWrapper>
        <LinkWrapper href={marketingGroupCode} target="_blank">
          <Text
            lineHeight="24px"
            className="subtitle"
            color={getSubtitleColor()}
          >
            {!isClosed && marketingGroupName}
          </Text>
        </LinkWrapper>
      </div>
    </div>
  );

  return (
    <StyledSummaryDescription $isBusiness={isBusiness} $action={action}>
      {productFeedLink ? (
        <>
          {isLinkInNewTab ? (
            <>{summaryBody}</>
          ) : (
            <LinkWrapper href={productFeedLink} target="_blank">
              {summaryBody}
            </LinkWrapper>
          )}
        </>
      ) : (
        <div className="container">{summaryBody}</div>
      )}
    </StyledSummaryDescription>
  );
};

export default React.memo(SummaryDescription);
