/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import {
  defaultTheme,
  Icons,
  LeadingText,
  LinkButton,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import {
  StyledAddPhone,
  StyledIcon,
  StyledInfoPanel,
  StyledLinkButton,
  StyledPhoneNumberInfo,
} from './style';
/** constants */
import { desktop1100 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const BaseProduct: FC = () => {
  const {
    pab2cMobileStore: { numberOfPhones },
    summaryDataStore: { maxCountMobilePhoneNumberForBind },
  } = useRootStore();
  const {
    setIsShowMyNumbers,
    bindNewNumberStore: { setIsShowBindNewNumberSP },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <StyledInfoPanel>
      <StyledPhoneNumberInfo>
        <StyledIcon icon={<Icons.SimPinkIcon />} />
        <div>
          <LeadingText color={defaultTheme.colors.black}>
            {numberOfPhones}
            <Text
              color={defaultTheme.colors.gray}
            >{`\u00A0/\u00A0${maxCountMobilePhoneNumberForBind}`}</Text>
          </LeadingText>
        </div>
      </StyledPhoneNumberInfo>
      <StyledLinkButton
        onClick={() => {
          setIsShowMyNumbers(true);
        }}
      >
        Мои номера
      </StyledLinkButton>
      {isDesktop1100 && (
        <StyledAddPhone>
          <StyledIcon icon={<Icons.CirclePlusIcon />} />
          <LinkButton onClick={() => setIsShowBindNewNumberSP(true)}>
            Добавить номер
          </LinkButton>
        </StyledAddPhone>
      )}
    </StyledInfoPanel>
  );
};

export default observer(BaseProduct);
