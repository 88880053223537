/** libraries */
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  H3,
  Icons,
  Loader,
  PriceTag,
  PriceTagBackgroundColor,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton, StyledFooter, StyledPrice } from './styles';
import { StyledIcon } from '../../style';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** components */
import MethodOfReceiptForm from '../../Components/MethodOfReceiptForm/MethodOfReceiptForm';

const SIMCardReplacement = (): JSX.Element => {
  const {
    authStore: { auth },
    pab2cMobileStore: { getMobilePhone, simCardReplacePrice },
    cityStore: { officeInfos },
  } = useRootStore();

  const {
    droplistSelectedNumberId,
    simCardReplacementStore: {
      isShowSIMCardReplacementStore,
      SIMCardReplacementSPHeaderText,
      result,
      replaceSimCardAgreement,
      isLoadingReplaceSimCardAgreement,
      isLoadingReplaceSimCard,
      onCloseSIMCardReplacementStoreSP,
      replaceSimCard,
    },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
  } = useMobileStore();
  /** TODO: где брать прайс замены SIM и доставки */
  const differenceBetweenBalanceAndPrice = auth.balance - simCardReplacePrice;
  const isAmount = differenceBetweenBalanceAndPrice >= 0;
  const mobilePhone = getMobilePhone(droplistSelectedNumberId);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  if (!isShowSIMCardReplacementStore) return null;

  return (
    <SidePage
      show={isShowSIMCardReplacementStore}
      headerText={!result.isResult && SIMCardReplacementSPHeaderText}
      onCloseClick={onCloseSIMCardReplacementStoreSP}
      footerContainer={
        !result.isResult && (
          <StyledFooter>
            <Button
              onClick={() =>
                replaceSimCard(
                  mobilePhone.simId,
                  deliveryType,
                  deliveryAddress(officeInfos),
                )
              }
              disabled={!replaceSimCardAgreement || !isAmount}
              loading={isLoadingReplaceSimCard}
            >
              Отправить заявку на замену SIM
            </Button>
            {!isAmount && (
              <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
                Недостаточно средств
              </Text>
            )}
          </StyledFooter>
        )
      }
    >
      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}
      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
      {!result.isResult && (
        <>
          <MethodOfReceiptForm />
          <StyledPrice>
            <PriceTag
              header="Стоимость замены"
              value={`${formatNumber(simCardReplacePrice)} ₽`}
              backgroundColor={
                isAmount
                  ? PriceTagBackgroundColor.OK
                  : PriceTagBackgroundColor.WARNING
              }
              fontColor={
                isAmount
                  ? defaultTheme.colors.black
                  : defaultTheme.colors.warning
              }
              subscription={
                isAmount
                  ? `На вашем счету ${formatNumber(auth.balance)} ₽`
                  : `Не хватает ${formatNumber(
                      Math.abs(differenceBetweenBalanceAndPrice),
                    )} ₽`
              }
              width="auto"
              headerType={isDesktop940 ? 'H2' : 'H3'}
            />
            {!isAmount && (
              <LinkWrapper href="/payment">
                <StyledButton styleType={ButtonStyleTypes.SECONDARY}>
                  {isDesktop940 ? 'Пополнить баланс' : 'Пополнить'}
                </StyledButton>
              </LinkWrapper>
            )}
          </StyledPrice>
          {isLoadingReplaceSimCardAgreement ? (
            <Loader />
          ) : (
            <Text lineHeight="24px">{parseHtml(replaceSimCardAgreement)}</Text>
          )}
        </>
      )}
    </SidePage>
  );
};

export default observer(SIMCardReplacement);
