import React, { useMemo } from 'react';
import {
  Button,
  ButtonStyleTypes,
  ButtonIconPosition,
  Icons,
} from 'cordis-core-ui-planeta';

import { CHANGE_NUMBER_STATUS_SP_TYPE } from '../../constants';

import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '~/components/Blocks/Templates/Pab2c/Mobile/store/useMobileStore';
import { MOBILE_ACTIONS } from '../../types';

const NumberListButtons = (msisdn: string): JSX.Element[] => {
  const {
    pab2cMobileStore: { mobilePhonesInfoData, getMobilePhone, getMobilePhones },
  } = useRootStore();
  const {
    changeNumberStatusStore: { isLoadingAction, openChangeNumberStatusType },
    changeNameStore: { changeNameButtonHandler },
    simCardReplacementStore: { openSIMCardReplacement },
    changeNumberStore: { changeNumberButtonHandler },
  } = useMobileStore();
  const mobilePhone = useMemo(() => {
    return getMobilePhone(msisdn);
  }, [mobilePhonesInfoData]);

  const blockButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.PowerIcon />}
      snoska="При краже или утрате"
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_1`}
      onClick={() =>
        openChangeNumberStatusType(
          CHANGE_NUMBER_STATUS_SP_TYPE.LOCK,
          mobilePhone,
          getMobilePhones,
        )
      }
      isDroplist
    >
      Заблокировать
    </Button>
  );

  const replaceSubscriberNameButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.ChangeIcon />}
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_2`}
      isDroplist
      onClick={() => changeNameButtonHandler(msisdn)}
    >
      Изменить имя
    </Button>
  );

  const replaceNumberButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.PhoneNumberIcon />}
      snoska="На текущей сим-карте"
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_3`}
      isDroplist
      onClick={() => changeNumberButtonHandler(msisdn)}
    >
      Изменить номер
    </Button>
  );

  const replaceSimCardButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.HistoryIcon />}
      snoska="Получение в офисе Планеты"
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_4`}
      onClick={() => {
        openSIMCardReplacement(mobilePhone);
      }}
      isDroplist
    >
      Заменить SIM-карту
    </Button>
  );

  const unbindButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.PowerIcon />}
      snoska="Отказ от номера"
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_5`}
      onClick={() =>
        openChangeNumberStatusType(
          CHANGE_NUMBER_STATUS_SP_TYPE.DISABLE,
          mobilePhone,
          getMobilePhones,
        )
      }
      isDroplist
    >
      Отключить
    </Button>
  );

  const unblockButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.PowerIcon />}
      snoska="При краже или утрате"
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key={`${msisdn}_6`}
      loading={isLoadingAction}
      onClick={() =>
        openChangeNumberStatusType(
          CHANGE_NUMBER_STATUS_SP_TYPE.UNLOCK,
          mobilePhone,
          getMobilePhones,
        )
      }
      isDroplist
    >
      Разблокировать
    </Button>
  );

  const buttonMapping = {
    [MOBILE_ACTIONS.Block]: blockButton(),
    [MOBILE_ACTIONS.ReplaceSubscriberName]: replaceSubscriberNameButton(),
    [MOBILE_ACTIONS.ReplaceNumber]: replaceNumberButton(),
    [MOBILE_ACTIONS.ReplaceSimCard]: replaceSimCardButton(),
    [MOBILE_ACTIONS.Unbind]: unbindButton(),
    [MOBILE_ACTIONS.Unblock]: unblockButton(),
  };

  return mobilePhone
    ? mobilePhone.availableMobileActions.map((action) => buttonMapping[action])
    : [];
};

export default NumberListButtons;
