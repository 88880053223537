import { flow, Instance, types } from 'mobx-state-tree';
import createApiPathModel from '~/stores/models/createApiPathModel';
import { DEFAULT_ERROR } from '~/constants/common';
import { VIEW_TYPES } from '../../../types';
import {
  getSuspendingAgreement,
  resumeService,
  suspendService,
} from '~/api/apiPab2c';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';

const RequestsStateModel = types.model('State', {
  resumeService: createApiPathModel(
    'POST /OperatingState/OperatingState/ResumeService',
  ),
  getSuspendingAgreement: createApiPathModel(
    'GET /OperatingState/OperatingState/GetSuspendingAgreement',
  ),
  suspendService: createApiPathModel(
    'POST /OperatingState/OperatingState/SuspendService',
  ),
});

const OperatingStateModel = types
  .model('OperatingStateModel', {
    requestsState: RequestsStateModel,
    isOperatingStateWizardShow: types.boolean,
    /** true - возобновление, false - приостановка */
    isResume: types.boolean,
    viewType: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
    supensionAgreement: types.maybeNull(types.string),
    /** Ошибка проверки приостановки обслуживания */
    errorSuspendService: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isLoadingResume() {
      return self.requestsState.resumeService.isLoading;
    },
    get isLoadingSuspensionAgreement() {
      return self.requestsState.getSuspendingAgreement.isLoading;
    },
    get isLoadingSuspension() {
      return self.requestsState.suspendService.isLoading;
    },
    get operatingStateTitle() {
      switch (self.viewType) {
        case VIEW_TYPES.PAUSE:
          return 'Приостановить обслуживание';
        case VIEW_TYPES.RESUME:
          return 'Возобновить обслуживание';
        default:
          return null;
      }
    },
  }))
  .actions((self) => ({
    setIsResumeOperatingStateWizardShow: (isOperatingStateWizardShow) => {
      self.isResume = true;
      self.isOperatingStateWizardShow = isOperatingStateWizardShow;
    },
    setIsSuspendOperationStateWizardShow: (isOperatingStateWizardShow) => {
      self.isResume = false;
      self.isOperatingStateWizardShow = isOperatingStateWizardShow;
    },
    setIsResume: (isResume) => {
      self.isResume = isResume;
    },
    setViewType: (viewType) => {
      self.viewType = viewType;
    },
    setErrorSuspendService: (errorSuspendService) => {
      self.errorSuspendService = errorSuspendService;
    },
    onCloseClick: (setOpenSPAfterAuthorizationState) => {
      self.isOperatingStateWizardShow = false;
      self.error = null;
      self.viewType = self.isResume ? VIEW_TYPES.RESUME : VIEW_TYPES.PAUSE;
      self.errorSuspendService = '';
      setOpenSPAfterAuthorizationState(null);
    },
    /** Возобновление обслуживания */
    resumeOperatingState: flow(function* (
      getVacation,
      setOpenSPAfterAuthorizationState,
      updateAuthCondition,
    ) {
      self.requestsState.resumeService.reset();
      self.requestsState.resumeService.setLoading();
      self.error = null;

      try {
        yield resumeService();
        yield getVacation();
        yield updateAuthCondition();
        self.viewType = VIEW_TYPES.RESUME_SUCCESS;
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.resumeService.setSuccess();
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(SIDE_PAGES.RESUMPTION_ACTION);
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        self.viewType = VIEW_TYPES.RESUME_ERROR;
        self.error = DEFAULT_ERROR;
        self.requestsState.resumeService.setFail();
      }
    }),
    /** Получение соглашения на приостановку обслуживания */
    getOperationStateAgreement: flow(function* (
      setOpenSPAfterAuthorizationState,
    ) {
      self.requestsState.getSuspendingAgreement.reset();
      self.requestsState.getSuspendingAgreement.setLoading();
      try {
        const res = yield getSuspendingAgreement();
        if (res) {
          self.supensionAgreement = res;
          self.isResume = false;
          self.isOperatingStateWizardShow = true;
        }
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.getSuspendingAgreement.setSuccess();
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(SIDE_PAGES.SUSPENDING_AGREEMENT);
          return;
        }
        self.errorSuspendService = DEFAULT_ERROR;
        self.isResume = false;
        self.isOperatingStateWizardShow = true;
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.getSuspendingAgreement.setFail();
      }
    }),
    /** Приостановка обслуживания */
    suspendOperatingState: flow(function* (
      getVacation,
      setOpenSPAfterAuthorizationState,
      updateAuthCondition,
    ) {
      self.requestsState.suspendService.reset();
      self.requestsState.suspendService.setLoading();
      self.error = null;

      try {
        yield suspendService();
        yield getVacation();
        yield updateAuthCondition();
        self.viewType = VIEW_TYPES.PAUSE_SUCCESS;
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.suspendService.setSuccess();
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(SIDE_PAGES.SUSPENDING_ACTION);
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        self.viewType = VIEW_TYPES.PAUSE_ERROR;
        self.error = DEFAULT_ERROR;
        self.requestsState.suspendService.setFail();
      }
    }),
  }))
  .actions((self) => ({
    changeOperatingState: flow(function* (
      getVacation,
      setOpenSPAfterAuthorizationState,
      updateAuthCondition,
    ) {
      if (self.isResume) {
        self.viewType = VIEW_TYPES.RESUME;
        yield self.resumeOperatingState(
          getVacation,
          setOpenSPAfterAuthorizationState,
          updateAuthCondition,
        );
      } else {
        self.viewType = VIEW_TYPES.PAUSE;
        yield self.suspendOperatingState(
          getVacation,
          setOpenSPAfterAuthorizationState,
          updateAuthCondition,
        );
      }
    }),
  }));
export default OperatingStateModel;
export type IOperatingStateStore = Instance<typeof OperatingStateModel>;
