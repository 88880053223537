import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { CHANGE_NUMBER_STATUS_SP_TYPE } from '../constants';
import { flow, getParent, Instance, types, cast } from 'mobx-state-tree';
import { maskPhone } from '~/utils/utils';
import { IMobileStore } from './MobileStore';
import {
  blockMobilePhone,
  getBlockMobilePhoneNumberAgreement,
  getUnbindMobilePhoneNumberAgreement,
  unbindMobilePhone,
  unblockMobilePhoneNumber,
} from '~/api/apiPab2c';
import { ContractResourceMobiles } from '../types';
import { DEFAULT_ERROR, DEFAULT_RESULT } from '~/constants/common';
import { Pab2cMobileStoreInstance } from '~/stores/Pab2cMobileStore';

const RequestsStateModel = types.model('ChangeNumberStatusStoreRequestsState', {
  getUnbindMobilePhoneAgreement: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/getUnbindMobilePhoneNumberAgreement',
  ),
  getBlockMobilePhoneAgreement: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/getBlockMobilePhoneNumberAgreement',
  ),
  unbindMobilePhone: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/UnbindMobilePhoneNumber',
  ),
  blockMobilePhone: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/BlockMobilePhoneNumber',
  ),
  unblockMobilePhone: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/unblockMobilePhoneNumber',
  ),
});

const ResultModel = types.model('ChangeNumberStatusStoreRequestModel', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const ChangeNumberStatusStore = types
  .model('ChangeNumberStatusStore', {
    requestsState: RequestsStateModel,
    isShowChangeNumStatusSP: types.boolean,
    agreement: types.maybeNull(types.string),
    /** Тип сп для смены статуса договора */
    changeNumberStatusType: types.maybeNull(
      types.enumeration(Object.values(CHANGE_NUMBER_STATUS_SP_TYPE)),
    ),
    result: ResultModel,
  })
  .views((self) => ({
    get changeNumStatusSPHeaderText() {
      const { droplistSelectedNumberId }: IMobileStore = getParent(self);
      const maskNumber = maskPhone(droplistSelectedNumberId);
      const headers = {
        [CHANGE_NUMBER_STATUS_SP_TYPE.LOCK]: `Заблокировать номер ${maskNumber}`,
        [CHANGE_NUMBER_STATUS_SP_TYPE.DISABLE]: `Отключить номер ${maskNumber}`,
      };
      return headers[self.changeNumberStatusType] || null;
    },
    get isLoadingAgreement() {
      return (
        self.requestsState.getBlockMobilePhoneAgreement.isLoading ||
        self.requestsState.getUnbindMobilePhoneAgreement.isLoading
      );
    },
    get isErrorAgreement() {
      return (
        self.requestsState.getBlockMobilePhoneAgreement.isFailed ||
        self.requestsState.getUnbindMobilePhoneAgreement.isFailed
      );
    },
    get isLoadingAction() {
      return (
        self.requestsState.blockMobilePhone.isLoading ||
        self.requestsState.unbindMobilePhone.isLoading ||
        self.requestsState.unblockMobilePhone.isLoading
      );
    },
  }))
  .actions((self) => ({
    onCloseChangeNumStatusSP: () => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      setDroplistSelectedNumberId('');
      self.isShowChangeNumStatusSP = false;
      self.changeNumberStatusType = null;
      self.agreement = null;
      self.result = DEFAULT_RESULT;
      self.requestsState = cast({
        getUnbindMobilePhoneAgreement: defaultModelState,
        getBlockMobilePhoneAgreement: defaultModelState,
        unbindMobilePhone: defaultModelState,
        blockMobilePhone: defaultModelState,
        unblockMobilePhone: defaultModelState,
      });
    },
    /** Соглашение на открепление номера */
    getUnbindMobilePhoneAgreement: flow(function* (simId: number) {
      self.requestsState.getUnbindMobilePhoneAgreement.reset();
      self.requestsState.getUnbindMobilePhoneAgreement.setLoading();
      try {
        const res = yield getUnbindMobilePhoneNumberAgreement(simId);
        self.agreement = res;
        self.requestsState.getUnbindMobilePhoneAgreement.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: DEFAULT_ERROR,
        };
        console.error('unbindMobilePhone', e);
        self.requestsState.getUnbindMobilePhoneAgreement.setFail();
      }
    }),
    /** Соглашение на добровольную блокировку номера */
    getBlockMobilePhoneAgreement: flow(function* (simId: number) {
      self.requestsState.getBlockMobilePhoneAgreement.reset();
      self.requestsState.getBlockMobilePhoneAgreement.setLoading();
      try {
        const res = yield getBlockMobilePhoneNumberAgreement(simId);
        self.agreement = res;
        self.requestsState.getBlockMobilePhoneAgreement.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: DEFAULT_ERROR,
        };
        console.error('unbindMobilePhone', e);
        self.requestsState.getBlockMobilePhoneAgreement.setFail();
      }
    }),
    unbindMobilePhone: flow(function* (
      simId: number,
      getMobilePhones: typeof Pab2cMobileStoreInstance['getMobilePhones'],
    ) {
      self.requestsState.unbindMobilePhone.reset();
      self.requestsState.unbindMobilePhone.setLoading();
      try {
        yield unbindMobilePhone(simId);
        yield getMobilePhones();
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Номер успешно отключён!',
        };
        self.requestsState.unbindMobilePhone.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: DEFAULT_ERROR,
        };
        console.error('unbindMobilePhone', e);
        self.requestsState.unbindMobilePhone.setFail();
      }
    }),
    blockMobilePhone: flow(function* (
      simId: number,
      getMobilePhones: typeof Pab2cMobileStoreInstance['getMobilePhones'],
    ) {
      self.requestsState.blockMobilePhone.reset();
      self.requestsState.blockMobilePhone.setLoading();
      try {
        yield blockMobilePhone(simId);
        yield getMobilePhones();
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Номер успешно заблокирован!',
        };
        self.requestsState.blockMobilePhone.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: DEFAULT_ERROR,
        };
        console.error('blockMobilePhone', e);
        self.requestsState.blockMobilePhone.setFail();
      }
    }),
    unblockMobilePhone: flow(function* (
      simId: number,
      getMobilePhones: typeof Pab2cMobileStoreInstance['getMobilePhones'],
    ) {
      self.requestsState.unblockMobilePhone.reset();
      self.requestsState.unblockMobilePhone.setLoading();
      try {
        yield unblockMobilePhoneNumber(simId);
        yield getMobilePhones();
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Номер успешно разблокирован!',
        };
        self.requestsState.unblockMobilePhone.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: DEFAULT_ERROR,
        };
        console.error('unblockMobilePhone', e);
        self.requestsState.unblockMobilePhone.setFail();
      }
    }),
  }))
  .actions((self) => ({
    /** Открыть сп изменение статуса номера */
    openChangeNumberStatusType: (
      type: CHANGE_NUMBER_STATUS_SP_TYPE,
      number: ContractResourceMobiles,
      getMobilePhones: typeof Pab2cMobileStoreInstance['getMobilePhones'],
    ) => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      setDroplistSelectedNumberId(number.msisdn);
      self.changeNumberStatusType = type;
      switch (type) {
        case CHANGE_NUMBER_STATUS_SP_TYPE.DISABLE:
          self.getUnbindMobilePhoneAgreement(number.simId);
          break;
        case CHANGE_NUMBER_STATUS_SP_TYPE.LOCK:
          self.getBlockMobilePhoneAgreement(number.simId);
          break;
        case CHANGE_NUMBER_STATUS_SP_TYPE.UNLOCK:
          self.unblockMobilePhone(number.simId, getMobilePhones);
          break;
        default:
          break;
      }
      self.isShowChangeNumStatusSP = true;
    },
  }));

export type IChangeNumberStatusStore = Instance<typeof ChangeNumberStatusStore>;

export const changeNumberStatusStoreInstance = {
  requestsState: {
    getUnbindMobilePhoneAgreement: defaultModelState,
    getBlockMobilePhoneAgreement: defaultModelState,
    unbindMobilePhone: defaultModelState,
    blockMobilePhone: defaultModelState,
    unblockMobilePhone: defaultModelState,
  },
  isShowChangeNumStatusSP: false,
  agreement: null,
  changeNumberStatusType: null,
  result: DEFAULT_RESULT,
};
