/** libraries */
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  LinkButton,
  PriceTag,
  ButtonStyleTypes,
  Button,
  PriceTagBackgroundColor,
  defaultTheme,
} from 'cordis-core-ui-planeta';
/** components */
import LinkWrapper from '~/components/LinkWrapper';

/** styles */
import { StyledTab, StyledH3, StyledLeadingText, StyledText } from './styles';
import { StyledPrice } from '../ChangeNumberSP/styles';

/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** utils */
import { formatNumber, maskPhone } from '~/utils/utils';
/** constants */
import { INIT_PHONE_VALUE } from '~/constants/common';
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import {
  activationTypeDeliveryMap,
  activationTypeSelectorMap,
  activationTypeTextMap,
  activationTypeTitleMap,
} from './constants';
import { ACTIVATION_METHOD_TYPE } from '../../constants';

const Tabs = (): JSX.Element => {
  const {
    cityStore: { officeInfos },
    authStore: { auth },
    summaryDataStore: { priceOnAdditionalMobilePhoneNumber },
  } = useRootStore();

  const {
    changeNumberStore: { newNumberInfo, setIsShowAvailableNumbersSp },
    choosingTheActivationMethodStore: {
      setIsShowChoosingTheActivationMethod,
      activationMethodType,
    },
    specifyTheTransferNumberStore: {
      phoneNumber,
      setIsShowSpecifyTheTransferNumber,
    },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
    choosingTheReceivingMethodStore: { setIsShowChoosingTheReceivingMethod },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const newNumberToBindMap = {
    [ACTIVATION_METHOD_TYPE.NEW]: newNumberInfo
      ? maskPhone(newNumberInfo.msisdn)
      : '',
    [ACTIVATION_METHOD_TYPE.TRANSFER]:
      phoneNumber === INIT_PHONE_VALUE ? 'Не указан' : maskPhone(phoneNumber),
  };

  const chooseNumberActionMap = {
    [ACTIVATION_METHOD_TYPE.NEW]: () => setIsShowAvailableNumbersSp(true),
    [ACTIVATION_METHOD_TYPE.TRANSFER]: () =>
      setIsShowSpecifyTheTransferNumber(true),
  };

  const numberSelectorText = () => {
    if (
      activationMethodType === ACTIVATION_METHOD_TYPE.TRANSFER &&
      phoneNumber === INIT_PHONE_VALUE
    )
      return 'Указать номер';
    return activationTypeSelectorMap[activationMethodType];
  };

  const isBalanceMoreThanBindPrice = auth.balance - newNumberInfo?.price >= 0;

  return (
    <>
      <StyledTab>
        <StyledLeadingText>Стоимость пользования номером</StyledLeadingText>
        <StyledH3>{`${formatNumber(
          priceOnAdditionalMobilePhoneNumber,
        )}\u00A0₽ в\u00A0день`}</StyledH3>
      </StyledTab>

      <StyledTab>
        <StyledLeadingText>Способ подключения</StyledLeadingText>
        <StyledH3>{activationTypeTextMap[activationMethodType]}</StyledH3>
        <LinkButton onClick={() => setIsShowChoosingTheActivationMethod(true)}>
          Изменить
        </LinkButton>
      </StyledTab>

      <StyledTab>
        <StyledLeadingText>
          {activationTypeTitleMap[activationMethodType]}
        </StyledLeadingText>
        <StyledH3>{newNumberToBindMap[activationMethodType]}</StyledH3>
        <LinkButton onClick={chooseNumberActionMap[activationMethodType]}>
          {numberSelectorText()}
        </LinkButton>
      </StyledTab>

      <StyledTab>
        <StyledLeadingText>Способ получения SIM-карты</StyledLeadingText>
        <StyledH3>{activationTypeDeliveryMap[deliveryType]}</StyledH3>
        <LinkButton onClick={() => setIsShowChoosingTheReceivingMethod(true)}>
          Изменить
        </LinkButton>
        <StyledText>{deliveryAddress(officeInfos)}</StyledText>
      </StyledTab>
      {newNumberInfo?.price > 0 && (
        <StyledTab>
          <StyledLeadingText>Стоимость добавления номера</StyledLeadingText>
          <StyledText>{`Категория ${newNumberInfo.categoryName}: ${formatNumber(
            newNumberInfo.price,
          )}\u000A₽`}</StyledText>
          <StyledPrice>
            <PriceTag
              value={`${formatNumber(newNumberInfo.price)} ₽`}
              backgroundColor={
                isBalanceMoreThanBindPrice
                  ? PriceTagBackgroundColor.OK
                  : PriceTagBackgroundColor.WARNING
              }
              subscription={
                isBalanceMoreThanBindPrice
                  ? `На вашем\u000Aсчету ${formatNumber(auth.balance)} ₽`
                  : `Не хватает ${formatNumber(
                      newNumberInfo.price - auth.balance,
                    )} ₽`
              }
              fontColor={
                isBalanceMoreThanBindPrice
                  ? defaultTheme.colors.black
                  : defaultTheme.colors.warning
              }
              headerType={isDesktop940 ? 'H2' : 'H3'}
              width="fit-content"
            />
            {!isBalanceMoreThanBindPrice && (
              <LinkWrapper href="/payment">
                <Button styleType={ButtonStyleTypes.SECONDARY}>
                  {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                </Button>
              </LinkWrapper>
            )}
          </StyledPrice>
        </StyledTab>
      )}
    </>
  );
};

export default observer(Tabs);
