/** libraries */
import styled from '@emotion/styled';
import { defaultTheme, H3, Icon, Link } from 'cordis-core-ui-planeta';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 6px;
  margin-bottom: 32px;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 26px;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledH3 = styled(H3)`
  margin: 0 0 0 14px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const StyledInfoPanel = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 0;
  width: 100%;
  border-top: 1px solid ${defaultTheme.colors.line};
  margin-top: 16px;
  padding-top: 21px;

  @media (min-width: ${desktop940}px) {
    width: initial;
    border-top: initial;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 3px;
  }
`;

export const StyledPhoneNumberInfo = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 17px;
  margin-bottom: -1px;

  ${StyledIcon} {
    margin-right: 12px;
  }
`;

export const StyledLinkButton = styled(Link)`
  margin-right: 0;

  @media (min-width: ${desktop1100}px) {
    margin-right: 22px;
  }
`;

export const StyledAddPhone = styled.div`
  display: flex;
  margin-bottom: -7px;
  margin-right: -5px;
`;
