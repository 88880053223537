import { STORAGE_TYPE } from './types';

export const NO_SIM_IMAGE = 'static/images/mobile-buy-sim.svg';
export const SUCCESS_IMAGE = 'static/images/mobile/success-order.svg';

export const UNIT_TYPE_NAME = {
  [STORAGE_TYPE.INTERNET]: 'ГБ',
  [STORAGE_TYPE.CALL]: 'минут',
  [STORAGE_TYPE.SMS]: 'СМС',
};

export const ZERO_BALANCE_TEXT = {
  [STORAGE_TYPE.INTERNET]: 'Мобильный интернет отключен',
  [STORAGE_TYPE.CALL]: 'Действует поминутная тарификация',
  [STORAGE_TYPE.SMS]: '',
};

export const PLURAL_PACKAGES = ['пакет', 'пакета', 'пакетов'];

export enum SERVICE_STATUS {
  ON = 'Обслуживается',
  STOP = 'Приостановлен',
  BLOCK = 'Заблокирован',
  SIM_NOT_ACTIVATED = 'Не активирована SIM',
  NOT_LINKED_TO_SIM = 'Не привязан к SIM',
}

export enum CHANGE_NUMBER_STATUS_SP_TYPE {
  /** Блокировка номера */
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  /** Отключение номера */
  DISABLE = 'DISABLE',
}

export enum ACTIVATION_METHOD_TYPE {
  /** Новый номер */
  NEW,
  /** Перенос номера */
  TRANSFER,
}

export const SELECT_VALUE = {
  label: '',
  value: '',
};

export const DELIVERY_TYPE_TABS = [
  {
    label: 'В офисе',
    value: 0,
  },
  {
    label: 'Курьером',
    value: 1,
  },
];

export const DELIVERY_TYPE_TABS_LABELS = DELIVERY_TYPE_TABS.map(
  (item) => item.label,
);

export const DELIVERY_TYPE_TABS_VALUES = DELIVERY_TYPE_TABS.map(
  (item) => item.value,
);
