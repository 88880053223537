import { createContext, useContext } from 'react';
import CallToActionStateModel, {
  ICallToActionStateStore,
} from './CallToActionStateStore';

const store = CallToActionStateModel.create({
  showSettings: false,
  enabledTimeShift: false,
  enabledRouter: false,
  enabledRemote: false,
  isOpenConnectionWizard: false,
  purchaseRemotePrice: null,
  isTransferMobileNumber: false,
});

const CallToActionStateModelStoreContext = createContext<ICallToActionStateStore>(
  store,
);

const useCallToActionStateStore = (): ICallToActionStateStore => {
  return useContext(CallToActionStateModelStoreContext);
};

export default useCallToActionStateStore;
