/** libraries */
import { Button, Text } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStateStore from '~/components/Blocks/Templates/Vacation/store/useVacationStore';
import useOperatingStateModelStore from '../../../ContractState/Components/OperatingStateWizard/store/useOperatingStateStore';
/** components */
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
/** utils */
import { formatNumber } from '~/utils/utils';
/** hook */
import { useMobileHook } from '../../MobileHook';
/** styles */
import { StyledWarning } from '../../style';

const ServiceStatusWarning = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
    vacationStore: { isOrdered, isActivated },
    pab2cBalanceStore: { moneyRecommended },
  } = useRootStore();
  const { setIsVacationShutdownOpen } = useVacationStateStore();
  const { setIsResumeOperatingStateWizardShow } = useOperatingStateModelStore();
  const { isProviderBlockForNonPayment, checkOperatingState } = useMobileHook();
  if (isProviderBlockForNonPayment) {
    return (
      <StyledWarning>
        <Text lineHeight="24px">
          Для управления номерами возобновите обслуживание. Внесите
          рекомендуемый платёж {formatNumber(Math.abs(moneyRecommended))} ₽
        </Text>
        <LinkWrapper href="/payment" target="_blank">
          <Button onClick={checkOperatingState}>Пополнить баланс</Button>
        </LinkWrapper>
      </StyledWarning>
    );
  }
  if (contractState === OPERATING_STATE.CLIENT_BLOCK) {
    return isOrdered || isActivated ? (
      <StyledWarning>
        <Text lineHeight="24px">
          Для управления номерами возобновите обслуживание.
        </Text>
        <Button onClick={() => setIsVacationShutdownOpen(true)}>
          Отключить каникулы
        </Button>
      </StyledWarning>
    ) : (
      <StyledWarning>
        <Text lineHeight="24px">
          Для управления номерами возобновите обслуживание.
        </Text>
        <Button onClick={() => setIsResumeOperatingStateWizardShow(true)}>
          Возобновить обслуживание
        </Button>
      </StyledWarning>
    );
  }
  return null;
};

export default ServiceStatusWarning;
