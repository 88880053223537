import { observer } from 'mobx-react';
import Portal from '~/components/Portal/Portal';
import MyNumbersSidePage from './MyNumbers/MyNumbersSidePage';
import DetailingTheLeftoversSP from './DetailingTheLeftovers/DetailingTheLeftoversSP';
import ChangeNumberStatus from './ChangeNumberStatus/ChangeNumberStatus';
import ChangeNameSP from './ChangeName/ChangeNameSP';
import ChoosingTheReceivingMethod from './ChoosingTheReceivingMethod/ChoosingTheReceivingMethod';
import SIMCardReplacement from './SIMCardReplacement/SIMCardReplacement';
import AvailableNumbersSp from './AvailableNumbersSp/AvailableNumbersSp';
import ChoosingTheActivationMethod from './ChoosingTheActivationMethod/ChoosingTheActivationMethod';
import SpecifyTheTransferNumber from './SpecifyTheTransferNumber/SpecifyTheTransferNumber';
import ChangeNumberSP from './ChangeNumberSP/ChangeNumberSP';
import BindNewNumberSP from './BindNewNumberSP/BindNewNumberSP';
import BindNewNumberAgreementSP from './BindNewNumberAgreementSP/BindNewNumberAgreementSP';

const SidePages = (): JSX.Element => {
  return (
    <>
      <Portal wrapperId="portalPrimary">
        {/* Детализация остатков */}
        <DetailingTheLeftoversSP />
        {/* Изменить статус номера */}
        <ChangeNumberStatus />
        <ChangeNameSP />
        {/* Выбор способа получения */}
        <ChoosingTheReceivingMethod />
        {/* Заказ замены SIM */}
        <SIMCardReplacement />
        <ChangeNumberSP />
        <AvailableNumbersSp />
        {/* Выбор способа подключения */}
        <ChoosingTheActivationMethod />
        {/* Указать номер переноса */}
        <SpecifyTheTransferNumber />
        <BindNewNumberAgreementSP />
      </Portal>
      <Portal>
        {/* Мои номера */}
        <MyNumbersSidePage />
        <BindNewNumberSP />
      </Portal>
    </>
  );
};

export default observer(SidePages);
