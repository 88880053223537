import { ACTIVATION_METHOD_TYPE } from '../../constants';
import { DELIVERY_TYPE } from '../../types';

export const activationTypeTextMap = {
  [ACTIVATION_METHOD_TYPE.NEW]: 'Новый номер в\u00A0Планете',
  [ACTIVATION_METHOD_TYPE.TRANSFER]: 'Перенос своего номера',
};

export const activationTypeTitleMap = {
  [ACTIVATION_METHOD_TYPE.NEW]: 'Новый номер',
  [ACTIVATION_METHOD_TYPE.TRANSFER]: 'Номер переноса',
};

export const activationTypeSelectorMap = {
  [ACTIVATION_METHOD_TYPE.NEW]: 'Выбрать другой',
  [ACTIVATION_METHOD_TYPE.TRANSFER]: 'Изменить номер',
};

export const activationTypeButtonTextMap = {
  [ACTIVATION_METHOD_TYPE.NEW]: 'Добавить номер',
  [ACTIVATION_METHOD_TYPE.TRANSFER]: 'Перенести номер',
};

export const activationTypeDeliveryMap = {
  [DELIVERY_TYPE.SELF_PICKUP]: 'В офисе',
  [DELIVERY_TYPE.COURIER]: 'Доставка курьером',
};

/** Коллекция типов ошибок получения соглашения на присоединение/замену нового мобильного телефонного номера */
export enum BIND_NUMBER_ERROR_TYPE {
  /** Дата снятия запрета смены номера на данной SIM-карте еще не наступила */
  TIME = 'MobileBanTrimDaysForReplaceException',
  /** Недостаточно денег для смены номера */
  MONEY = 'MobileNotEnoughMoneyException',
  /** ConfigurationError */
  CONFIG = 'MobileException',
  /** Номер телефона уже используется */
  IS_USING = 'MobilePhoneNumberIsAlreadyUsedException',
  /** Превышен лимит созданных заявок в день */
  DAY_LIMIT = 'ClientRequestDemandsException',
  /** Превышен лимит прикрепленных номеров */
  SIM_LIMIT = 'MaxMobilePhoneNumberCountForBindLimitExceededException',
}
