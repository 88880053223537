/** libraries */
import { Instance, types } from 'mobx-state-tree';
/** constants */
import { ACTIVATION_METHOD_TYPE } from '../constants';

export const ChoosingTheActivationMethodStore = types
  .model('ChoosingTheActivationMethodStore', {
    isShowChoosingTheActivationMethod: types.boolean,
    activationMethodType: types.number,
  })
  .actions((self) => ({
    setIsShowChoosingTheActivationMethod: (
      isShowChoosingTheActivationMethod,
    ) => {
      self.isShowChoosingTheActivationMethod = isShowChoosingTheActivationMethod;
    },
    setActivationMethodType: (activationMethodType) => {
      self.activationMethodType = activationMethodType;
    },
    onCloseChoosingTheActivationMethod: () => {
      self.isShowChoosingTheActivationMethod = false;
    },
  }));

export type IChoosingTheActivationMethodStore = Instance<
  typeof ChoosingTheActivationMethodStore
>;

export const choosingTheActivationMethodStoreInstance = {
  isShowChoosingTheActivationMethod: false,
  activationMethodType: ACTIVATION_METHOD_TYPE.NEW,
};
