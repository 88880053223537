/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { Loader } from 'cordis-core-ui-planeta';
/** components */
import NoSim from './Components/NoSim/NoSim';
import MobileInfo from './Components/MobileInfo/MobileInfo';
import { MobileStoreProvider } from './store/useMobileStore';
import SidePages from './SidePages';
/** styles */
import { LoaderWrapper, StyledMobile } from './style';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/** Блок «РМП. Моя связь»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=803921992
 */
const Mobile: FC = () => {
  const {
    pab2cMobileStore: { subscriptions, isConvergentProduct, isLoading },
  } = useRootStore();

  return (
    <MobileStoreProvider>
      <StyledMobile>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!isLoading && !isConvergentProduct && subscriptions.length === 0 && (
          <NoSim />
        )}
        {!isLoading && (isConvergentProduct || subscriptions.length > 0) && (
          <MobileInfo />
        )}
        <SidePages />
      </StyledMobile>
    </MobileStoreProvider>
  );
};

export default observer(Mobile);
