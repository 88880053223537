/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { Text, defaultTheme, Icon, Icons } from 'cordis-core-ui-planeta';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
/** styles */
import {
  ImageWrapper,
  StyledButton,
  StyledDescription,
  StyledH3,
  StyledNoSim,
  StyledText,
} from './style';
/** constants */
import { NO_SIM_IMAGE } from '../../constants';
import { desktop500 } from '~/components/Grid/constants';

const NoSim: FC = () => {
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const imageLoader = ({ src, width, quality }) => {
    return `${process.env.STATIC_SERVER}/${src}?w=${width}&q=${quality}`;
  };

  const iconSim = <Icon icon={<Icons.SimWhiteIcon />} />;

  return (
    <StyledNoSim>
      <StyledDescription>
        <Icon icon={<Icons.MobileIcon />} />
        <StyledH3>Моя связь</StyledH3>
        <StyledText lineHeight="24px">
          Подключите мобильную связь Планеты! Переходите со&nbsp;своим номером
          или подключите новый.
        </StyledText>
        <Text lineHeight="24px">
          Будьте на&nbsp;связи с&nbsp;близкими, наслаждайтесь быстрым мобильным
          интернетом и&nbsp;не&nbsp;пропускать важные сообщения. Подключайтесь!
        </Text>
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
          icon={iconSim}
        >
          Заказать SIM
        </StyledButton>
      </StyledDescription>
      {isDesktop500 && (
        <ImageWrapper>
          <Image
            loader={imageLoader}
            src={NO_SIM_IMAGE}
            alt="Подключите мобильную связь от Планеты"
            width={336}
            height={336}
            quality={100}
            layout="fixed"
          />
        </ImageWrapper>
      )}
    </StyledNoSim>
  );
};

export default observer(NoSim);
