/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton, StyledMobileInfo, StyledWarning } from './style';
/** interfaces */
import { STORAGE_TYPE } from '../../types';
/** components */
import Header from '../Header/Header';
import InfoPanel from '../Header/InfoPanel';
import MobileScale from '../MobileScale/MobileScale';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { OPERATING_STATE } from '~/constants/common';

const MobileInfo: FC = () => {
  const {
    pab2cMobileStore: { isConvergentProduct },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
  } = useRootStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  return (
    <StyledMobileInfo $isConvergent={isConvergentProduct}>
      <Header />
      <MobileScale type={STORAGE_TYPE.INTERNET} />
      <MobileScale type={STORAGE_TYPE.CALL} />
      {isConvergentProduct && <MobileScale type={STORAGE_TYPE.SMS} />}
      {isSuspendedCondition && (
        <StyledWarning
          text={
            <Text>
              Доступны только входящие звонки и&nbsp;вызовы на&nbsp;специальные
              номера. Мобильный интернет отключен.
            </Text>
          }
        />
      )}
      {!isSuspendedCondition && (
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
        >
          {isDesktop940 ? 'Добавить пакет минут или ГБ' : 'Добавить пакет'}
        </StyledButton>
      )}
      {!isDesktop940 && <InfoPanel />}
    </StyledMobileInfo>
  );
};

export default observer(MobileInfo);
