/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { Icon, Icons } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** components */
import InfoPanel from './InfoPanel';
import { StyledH3, StyledHeader, StyledTitle } from './style';

const Header: FC = () => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledHeader>
      <StyledTitle>
        <Icon icon={<Icons.MobileIcon />} />
        <StyledH3>Моя связь</StyledH3>
      </StyledTitle>
      {isDesktop940 && <InfoPanel />}
    </StyledHeader>
  );
};

export default observer(Header);
