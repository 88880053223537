/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  SidePage,
  Icons,
  LinkButton,
} from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** styles */
import { StyledAddNumber, StyledButton, StyledNumbers } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** hook */
import { useMobileHook } from '../../MobileHook';
/** components */
import NumberListItem from '../../Components/NumberListItem/NumberListItem';
import ServiceStatusWarning from '../../Components/ServiceStatusWarning/ServiceStatusWarning';

const MyNumbersSidePage = (): JSX.Element => {
  const {
    pab2cMobileStore: { mobilePhones },
  } = useRootStore();
  const {
    isShowMyNumbers,
    setIsShowMyNumbers,
    bindNewNumberStore: { setIsShowBindNewNumberSP },
  } = useMobileStore();

  const { timerId } = useMobileHook();

  const onCloseSP = () => {
    clearInterval(timerId);
    setIsShowMyNumbers(false);
  };

  const addNumberButtonHandler = () => setIsShowBindNewNumberSP(true);

  useEffect(() => {
    const handlerEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') clearInterval(timerId);
    };
    document.addEventListener('keydown', handlerEscape);
    return () => {
      document.removeEventListener('keydown', handlerEscape);
    };
  }, [isShowMyNumbers]);

  const addNumber = (
    <StyledAddNumber>
      <Icons.CirclePlusIcon />
      <LinkButton
        color={defaultTheme.colors.planeta}
        onClick={addNumberButtonHandler}
      >
        Добавить номер
      </LinkButton>
    </StyledAddNumber>
  );
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <SidePage
      show={isShowMyNumbers}
      headerText="Мои номера"
      headerIcon={isDesktop940 ? addNumber : null}
      onCloseClick={onCloseSP}
      footerContainer={
        !isDesktop940 ? (
          <StyledButton onClick={addNumberButtonHandler}>
            Добавить номер
          </StyledButton>
        ) : null
      }
    >
      <ServiceStatusWarning />
      {mobilePhones?.length > 0 &&
        mobilePhones?.map((item) => (
          <StyledNumbers key={item.msisdn}>
            <NumberListItem msisdn={item.msisdn} />
          </StyledNumbers>
        ))}
    </SidePage>
  );
};

export default observer(MyNumbersSidePage);
