import { ReactNode, useContext } from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import { CallToActionFields } from '../../CallToAction.types';
import createStore, { IMainCallToActionStore } from './MainCallToActionStore';

interface CallToActionProps {
  children: ReactNode;
  fields: CallToActionFields;
}

export const CallToActionProvider = ({
  children,
  fields,
}: CallToActionProps): JSX.Element => {
  const store = useLocalStore<IMainCallToActionStore>(() =>
    createStore(fields),
  );
  return <Provider CallToActionStore={store}>{children}</Provider>;
};

export const useCallToActionStore = (): IMainCallToActionStore => {
  const { CallToActionStore } = useContext(MobXProviderContext);
  return CallToActionStore;
};
