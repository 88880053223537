/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  SidePage,
  Button,
  Icons,
  H2,
  H3,
  LinkButton,
  Loader,
  Text,
  PriceTagBackgroundColor,
  defaultTheme,
  ButtonStyleTypes,
  LeadingText,
  PriceTag,
} from 'cordis-core-ui-planeta';
/** components */
import LinkWrapper from '~/components/LinkWrapper';
/** styles */
import {
  StyledH3,
  StyledWarning,
  StyledNewNumber,
  StyledIcon,
  StyledPrice,
  StyledContainer,
  StyledButton,
} from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** utils */
import { formatNumber, maskPhone } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { BIND_NUMBER_ERROR_TYPE } from '../BindNewNumberSP/constants';

const ChangeNumberSP = (): JSX.Element => {
  const {
    pab2cMobileStore: { getMobilePhone, getMobileInfo },
    authStore: { auth },
  } = useRootStore();

  const {
    changeNumberStore: {
      isShowChangeNumberSp,
      selectedNumberId,
      resetChangeNumberStore,
      newNumberInfo,
      setIsShowAvailableNumbersSp,
      agreement,
      getReplaceNumberAgreement,
      replaceMobilePhoneNumber,
      result,
      isLoading,
      isAgreementLoading,
      errorType,
      parsedDateForReplaceNumber,
      getChangeNumberData,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const mobilePhone = selectedNumberId && getMobilePhone(selectedNumberId);

  useEffect(() => {
    if (!isShowChangeNumberSp) return;
    (async () => {
      await getChangeNumberData();
    })();
  }, [isShowChangeNumberSp]);

  useEffect(() => {
    if (!isShowChangeNumberSp || !mobilePhone || !newNumberInfo) return;
    (async () => {
      await getReplaceNumberAgreement(mobilePhone.simId, newNumberInfo.id);
    })();
  }, [isShowChangeNumberSp, mobilePhone?.simId, newNumberInfo?.id]);

  const closeClickHandler = async () => {
    if (result.isResult && result.isCorrect) {
      await getMobileInfo();
      await getChangeNumberData();
    }
    resetChangeNumberStore();
  };

  const Footer = () => (
    <StyledButton
      onClick={async () => {
        await replaceMobilePhoneNumber(mobilePhone.simId, newNumberInfo.id);
      }}
      loading={isLoading}
      disabled={!newNumberInfo || !agreement}
    >
      Изменить номер
    </StyledButton>
  );

  if (!isShowChangeNumberSp) return null;

  return (
    <SidePage
      show={isShowChangeNumberSp}
      headerText={
        !result.isResult &&
        `Изменить номер ${selectedNumberId && maskPhone(mobilePhone?.msisdn)}`
      }
      onCloseClick={closeClickHandler}
      footerContainer={!result.isResult && <Footer />}
      showMobileHeader
    >
      {(!newNumberInfo || isAgreementLoading) && <Loader small />}

      {!result.isResult && newNumberInfo && !isAgreementLoading && (
        <StyledContainer>
          <LeadingText color={defaultTheme.colors.black}>
            Ваш будущий номер
          </LeadingText>
          <StyledNewNumber>
            <StyledH3>{maskPhone(newNumberInfo.msisdn)}</StyledH3>
            <LinkButton onClick={() => setIsShowAvailableNumbersSp(true)}>
              Выбрать другой
            </LinkButton>
          </StyledNewNumber>
          <StyledWarning>
            <Text lineHeight="24px">
              {`Перед изменением номера отвяжите его от банковских карт и других
              аккаунтов.${
                errorType === BIND_NUMBER_ERROR_TYPE.TIME
                  ? ` Следующее изменение номера будет доступно через ${parsedDateForReplaceNumber}.`
                  : ''
              }`}
            </Text>
          </StyledWarning>
          {(newNumberInfo?.price > 0 ||
            errorType === BIND_NUMBER_ERROR_TYPE.MONEY) && (
            <>
              <LeadingText color={defaultTheme.colors.black}>
                Стоимость замены
              </LeadingText>
              <StyledPrice>
                <PriceTag
                  value={`${formatNumber(newNumberInfo.price)} ₽`}
                  backgroundColor={
                    errorType === BIND_NUMBER_ERROR_TYPE.MONEY
                      ? PriceTagBackgroundColor.WARNING
                      : PriceTagBackgroundColor.OK
                  }
                  subscription={
                    errorType === BIND_NUMBER_ERROR_TYPE.MONEY
                      ? `Не хватает ${formatNumber(
                          newNumberInfo.price - auth.balance,
                        )} ₽`
                      : `На вашем${'\u000A'}счету ${formatNumber(
                          auth.balance,
                        )} ₽`
                  }
                  fontColor={
                    errorType === BIND_NUMBER_ERROR_TYPE.MONEY
                      ? defaultTheme.colors.warning
                      : defaultTheme.colors.black
                  }
                  headerType={isDesktop940 ? 'H2' : 'H3'}
                  width="fit-content"
                />
                {errorType === BIND_NUMBER_ERROR_TYPE.MONEY && (
                  <LinkWrapper href="/payment">
                    <Button styleType={ButtonStyleTypes.SECONDARY}>
                      {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                    </Button>
                  </LinkWrapper>
                )}
              </StyledPrice>
            </>
          )}
          {agreement && <Text>{parseHtml(agreement)}</Text>}
        </StyledContainer>
      )}

      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
    </SidePage>
  );
};

export default observer(ChangeNumberSP);
