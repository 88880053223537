/** libraries */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, defaultTheme } from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** components */
import { StyledMobileScale } from '../MobileScale/style';
import Warning from '~/components/Blocks/Shared/Warning/Warning';

export const StyledMobileInfo = styled.div<{ $isConvergent?: boolean }>`
  ${({ $isConvergent }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      padding-right: 32px;
      align-items: baseline;

      ${StyledMobileScale}:nth-of-type(2) {
        border-bottom: 1px solid ${defaultTheme.colors.line};
      }

      ${
        $isConvergent &&
        `${StyledMobileScale}:nth-of-type(3) {
        border-bottom: 1px solid ${defaultTheme.colors.line};
        }`
      }

      @media (min-width: ${desktop940}px) {
        ${StyledMobileScale}:nth-of-type(2) {
          border-bottom: initial;
        }

        ${
          $isConvergent &&
          `${StyledMobileScale}:nth-of-type(3) {
            border-bottom: initial;
            width: 71%;
            margin-right: 3%;
          }
          ${StyledMobileScale}:nth-of-type(4) {
            width: 26%;
          }`
        }

      }
    }`;
  }}
`;

export const StyledButton = styled(Button)`
  margin-bottom: 16px;
  width: 100%;
  white-space: nowrap;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 0;
    width: initial;
  }
`;

export const StyledWarning = styled(Warning)`
  width: 100%;

  span {
    max-width: 600px;
  }
`;
