/** libraries */
import { flow, Instance, types } from 'mobx-state-tree';
/** api */
import { checkPortabilityMobilePhoneNumber } from '~/api/apiPab2c';
/** constants */
import {
  DEFAULT_ERROR,
  INIT_PHONE_VALUE,
  INITIAL_RESULT,
  PHONE_NUMBER_LENGTH,
} from '~/constants/common';
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { CHECK_PORTABILITY_NUMBER_ERROR_TEXT } from '../SidePages/SpecifyTheTransferNumber/constants';

const RequestsStateModel = types.model('State', {
  сheckPortabilityNumber: createApiPathModel(
    'POST /Mobile/MobileNumberPortability/CheckPortabilityMobilePhoneNumber',
  ),
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const SpecifyTheTransferNumberStore = types
  .model('SpecifyTheTransferNumberStore', {
    requestsState: RequestsStateModel,
    isShowSpecifyTheTransferNumber: types.boolean,
    phoneNumber: (types.string, ''),
    candidateToPhoneNumber: (types.string, ''),
    phoneNumberError: (types.string, ''),
    result: ResultModel,
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.сheckPortabilityNumber.isLoading;
    },
  }))
  .actions((self) => ({
    setIsShowSpecifyTheTransferNumber: (
      isShowSpecifyTheTransferNumber: boolean,
    ) => {
      self.isShowSpecifyTheTransferNumber = isShowSpecifyTheTransferNumber;
    },
    onChangePhoneNumber: (event) => {
      const { value, errorText } = event;
      self.candidateToPhoneNumber = value;
      self.phoneNumberError = errorText
        ? 'Введите корректный номер телефона'
        : '';
    },
    onBlurPhoneNumber: () => {
      if (self.candidateToPhoneNumber.length < PHONE_NUMBER_LENGTH) {
        self.phoneNumberError = 'Введите корректный номер телефона';
        return true;
      }
      return false;
    },
    changePhoneNumberButtonHandler: flow(function* (mobilePhoneNumber: string) {
      self.requestsState.сheckPortabilityNumber.reset();
      self.requestsState.сheckPortabilityNumber.setLoading();
      try {
        const formattedNumber = mobilePhoneNumber.replace(/\+| |-/g, '');
        yield checkPortabilityMobilePhoneNumber(formattedNumber);
        self.phoneNumber = self.candidateToPhoneNumber;
        self.isShowSpecifyTheTransferNumber = false;
        self.requestsState.сheckPortabilityNumber.setSuccess();
      } catch (e) {
        console.error('changePhoneNumberButtonHandler', e);
        const error = JSON.parse(e.errorMessage);
        self.phoneNumberError =
          CHECK_PORTABILITY_NUMBER_ERROR_TEXT?.[error.Type] || DEFAULT_ERROR;
        self.requestsState.сheckPortabilityNumber.setFail();
      }
    }),
    onCloseSpecifyTheTransferNumber: () => {
      self.isShowSpecifyTheTransferNumber = false;
      self.candidateToPhoneNumber = self.phoneNumberError
        ? self.phoneNumber
        : INIT_PHONE_VALUE;
      self.phoneNumberError = '';
    },
  }));

export type ISpecifyTheTransferNumberStore = Instance<
  typeof SpecifyTheTransferNumberStore
>;

export const specifyTheTransferNumberStoreInstance = {
  requestsState: {
    сheckPortabilityNumber: defaultModelState,
  },
  isShowSpecifyTheTransferNumber: false,
  phoneNumber: INIT_PHONE_VALUE,
  candidateToPhoneNumber: INIT_PHONE_VALUE,
  phoneNumberError: '',
  result: INITIAL_RESULT,
};
